@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
    overflow-x: hidden;
    font-family:  Helvetica, sans-serif;
    --primary-color: #6bb6e7;
    --secondary-color: #6be675;
    --background-color: #061721;
}

html{
    scroll-behavior: smooth;
}

.heading-text{
  font-weight: 900;
  color: #061721;
  @apply text-2xl md:text-4xl
}

.sub-heading-text{
  color: var(--secondary-color);
  @apply text-xl font-black -mb-2 drop-shadow-sm
  
}

.text-bg{
  background-color: #6be675;
  padding: 0 0.2rem;
  color: white;
}

.paragraph{
  line-height: 1.8rem;
  font-size: 1.2rem;
  padding: 1rem 0;
  @apply text-stone-700
}



.slider-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.slider-wrapper {
  position: relative;
  width: 100%;
}

.slider-content {
  display: flex;
  transition: transform 0.5s ease;
}

.slide {
  min-width: 100%;
}

.slide-button {
  position: absolute;
  top: 50%;
  transform: translateY(300%);
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}



































/* Define the keyframes for the color transition animation */
@keyframes colorChange {
    0% {
      background-color: var(--primary-color);
    }
    50% {
      background-color: var(--secondary-color);
    }
    100% {
      background-color: var(--primary-color);
    }
  }
  
  /* Apply the animation to a class */
  .color-changing-bg {
    animation: colorChange 30s infinite; /* Adjust duration as needed */
    background-size: 200% 200%; /* Ensures smooth gradient transition */
  }

.nav-links{
    margin-top: 25px;
    transition: margin-top 1s linear ease-in-out;
    font-weight: bold;
}

.navbar{
    animation: scaleNavbar 1s forwards cubic-bezier(0.445, 0.05, 0.55, 0.95) ;
    margin-top: -100px;
    transform: scale(0.6);
    z-index: 10000000;
}


@keyframes scaleNavbar {
    from {
        transform: scale(0.6);
        margin-top: -100px;
    }
    to {
        transform: scale(01);
        margin-top: 0;
        
    }
}

.header-bg {
    /* background-color: #061721; */
    background: #fafafa;
}
.go-green {
    left: -500px;
    animation: slideToRight 1s ease-in-out forwards 1.5s;
    position: absolute;
}


@keyframes slideToRight {
    from {
        left: -500px;
    }
    to {
        left: 0;
    }
}
.suvImage {
  transform: scale(0);
  opacity: 0;
  animation: showCar 1.5s forwards cubic-bezier(0.34, 1.56, 0.64, 1) 3s;
}

/* Base animation for all screen sizes */
@keyframes showCar {
  0% {
      transform: scale(0);
      opacity: 0;
  }
  99% {
      transform: scale(1.1);
      opacity: 1;
  }
  100% {
      transform: scale(0.9);
      opacity: 1;
  }
}

/* Media query for medium-sized screens */
@media (max-width: 768px) {
  .suvImage {
      animation: showCarMedium 1.5s forwards cubic-bezier(0.34, 1.56, 0.64, 1) 3s;
  }

  @keyframes showCarMedium {
      0% {
          transform: scale(0);
          opacity: 0;
      }
      99% {
          transform: scale(1.3); /* Larger scale for medium screens */
          opacity: 1;
      }
      100% {
          transform: scale(1.4); /* Adjust the final scale as needed */
          opacity: 1;
      }
  }
}


.about-suv{
    transform: scaleX(-1);
}

.sub-heading{
    color: var(--secondary-color);
    font-weight: bold;
}


/* Add this to your global CSS file or in a CSS module */
@keyframes slideInFromLeft {
    from {
      transform: translateX(-100%) scaleX(-1);
      
      opacity: 0;
    }
    to {
      transform: translateX(0) scaleX(-1);
      opacity: 1;
    }
  }
  
  .animate-slide-in {
    animation: slideInFromLeft 1s ease-out forwards;
  }
  
  /* Initially hide the image */
  .slide-in-hidden {
    opacity: 0;
  }


/* .header-content{
    z-index: 100000 !important;
    position: absolute;
    left: 0;
    top: 200PX;
} */
/* 
<Spline
className=" h-screen w-full scale-90 mx-auto -mt-36"
  scene="https://prod.spline.design/HaOCHFMFnr2Up4CD/scene.splinecode" 
/> */